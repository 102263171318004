import Timer from './components/Timer';
import './styles/App.css'

function App() {
  return (
    <div className="App">
      <button className='btn btn-sm btn-accent fixed bottom-0 right-0 m-4 z-50' onClick={() => {
        window.open("https://open.kakao.com/o/sL2b5kfg")
      }}>
        문의
      </button>
      <Timer />
    </div>
  );
}

export default App;
